.wrap {
	@include wrap-style;

	&--small {
		@include wrap(738px, 15px);

		@include media('md') {
			@include wrap(738px, 30px);
		}
	}

	&--medium {
		@include wrap(848px, 15px);

		@include media('md') {
			@include wrap(848px, 30px);
		}
	}

	&--default {
		@include wrap(500px, 15px);

		@include media('md') {
			@include wrap(820px, 30px);
		}

		@include media('lg') {
			@include wrap(1178px, 30px);
		}
	}
}
