.ql-container {
	position: relative;
	box-sizing: border-box;
	height: 100%;
	margin: 0px;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 13px;
}

.ql-container.ql-disabled .ql-tooltip {
	visibility: hidden;
}

.ql-container.ql-disabled .ql-editor ul[data-checked] > li:before {
	pointer-events: none;
}

.ql-clipboard {
	position: absolute;
	top: 50%;
	left: -100000px;
	height: 1px;
	overflow-y: hidden;
}

.ql-clipboard p {
	margin: 0;
	padding: 0;
}

.ql-editor {
	box-sizing: border-box;
	height: 100%;
	padding: 12px 15px;
	overflow-y: auto;
	outline: none;
	line-height: 1.42;
	text-align: left;
	word-wrap: break-word;
	white-space: pre-wrap;
	tab-size: 4;
	tab-size: 4;
}

.ql-editor > * {
	cursor: text;
}

.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
	margin: 0;
	padding: 0;
	counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol,
.ql-editor ul {
	padding-left: 1.5em;
}

.ql-editor ol > li,
.ql-editor ul > li {
	list-style-type: none;
}

.ql-editor ul > li:before {
	content: '\2022';
}

.ql-editor ul[data-checked='true'],
.ql-editor ul[data-checked='false'] {
	pointer-events: none;
}

.ql-editor ul[data-checked='true'] > li *,
.ql-editor ul[data-checked='false'] > li * {
	pointer-events: all;
}

.ql-editor ul[data-checked='true'] > li:before,
.ql-editor ul[data-checked='false'] > li:before {
	color: #777;
	cursor: pointer;
	pointer-events: all;
}

.ql-editor ul[data-checked='true'] > li:before {
	content: '\2611';
}

.ql-editor ul[data-checked='false'] > li:before {
	content: '\2610';
}

.ql-editor li:before {
	display: inline-block;
	width: 1.2em;
	white-space: nowrap;
}

.ql-editor li:not(.ql-direction-rtl):before {
	margin-right: 0.3em;
	margin-left: -1.5em;
	text-align: right;
}

.ql-editor li.ql-direction-rtl:before {
	margin-right: -1.5em;
	margin-left: 0.3em;
}

.ql-editor ol li:not(.ql-direction-rtl),
.ql-editor ul li:not(.ql-direction-rtl) {
	padding-left: 1.5em;
}

.ql-editor ol li.ql-direction-rtl,
.ql-editor ul li.ql-direction-rtl {
	padding-right: 1.5em;
}

.ql-editor ol li {
	counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	counter-increment: list-0;
}

.ql-editor ol li:before {
	content: counter(list-0, decimal) '. ';
}

.ql-editor ol li.ql-indent-1 {
	counter-increment: list-1;
}

.ql-editor ol li.ql-indent-1:before {
	content: counter(list-1, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-1 {
	counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-2 {
	counter-increment: list-2;
}

.ql-editor ol li.ql-indent-2:before {
	content: counter(list-2, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-2 {
	counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-3 {
	counter-increment: list-3;
}

.ql-editor ol li.ql-indent-3:before {
	content: counter(list-3, decimal) '. ';
}

.ql-editor ol li.ql-indent-3 {
	counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-4 {
	counter-increment: list-4;
}

.ql-editor ol li.ql-indent-4:before {
	content: counter(list-4, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-4 {
	counter-reset: list-5 list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-5 {
	counter-increment: list-5;
}

.ql-editor ol li.ql-indent-5:before {
	content: counter(list-5, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-5 {
	counter-reset: list-6 list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-6 {
	counter-increment: list-6;
}

.ql-editor ol li.ql-indent-6:before {
	content: counter(list-6, decimal) '. ';
}

.ql-editor ol li.ql-indent-6 {
	counter-reset: list-7 list-8 list-9;
}

.ql-editor ol li.ql-indent-7 {
	counter-increment: list-7;
}

.ql-editor ol li.ql-indent-7:before {
	content: counter(list-7, lower-alpha) '. ';
}

.ql-editor ol li.ql-indent-7 {
	counter-reset: list-8 list-9;
}

.ql-editor ol li.ql-indent-8 {
	counter-increment: list-8;
}

.ql-editor ol li.ql-indent-8:before {
	content: counter(list-8, lower-roman) '. ';
}

.ql-editor ol li.ql-indent-8 {
	counter-reset: list-9;
}

.ql-editor ol li.ql-indent-9 {
	counter-increment: list-9;
}

.ql-editor ol li.ql-indent-9:before {
	content: counter(list-9, decimal) '. ';
}

.ql-editor .ql-indent-1:not(.ql-direction-rtl) {
	padding-left: 3em;
}

.ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
	padding-left: 4.5em;
}

.ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
	padding-right: 3em;
}

.ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
	padding-right: 4.5em;
}

.ql-editor .ql-indent-2:not(.ql-direction-rtl) {
	padding-left: 6em;
}

.ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
	padding-left: 7.5em;
}

.ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
	padding-right: 6em;
}

.ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
	padding-right: 7.5em;
}

.ql-editor .ql-indent-3:not(.ql-direction-rtl) {
	padding-left: 9em;
}

.ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
	padding-left: 10.5em;
}

.ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
	padding-right: 9em;
}

.ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
	padding-right: 10.5em;
}

.ql-editor .ql-indent-4:not(.ql-direction-rtl) {
	padding-left: 12em;
}

.ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
	padding-left: 13.5em;
}

.ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
	padding-right: 12em;
}

.ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
	padding-right: 13.5em;
}

.ql-editor .ql-indent-5:not(.ql-direction-rtl) {
	padding-left: 15em;
}

.ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
	padding-left: 16.5em;
}

.ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
	padding-right: 15em;
}

.ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
	padding-right: 16.5em;
}

.ql-editor .ql-indent-6:not(.ql-direction-rtl) {
	padding-left: 18em;
}

.ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
	padding-left: 19.5em;
}

.ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
	padding-right: 18em;
}

.ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
	padding-right: 19.5em;
}

.ql-editor .ql-indent-7:not(.ql-direction-rtl) {
	padding-left: 21em;
}

.ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
	padding-left: 22.5em;
}

.ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
	padding-right: 21em;
}

.ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
	padding-right: 22.5em;
}

.ql-editor .ql-indent-8:not(.ql-direction-rtl) {
	padding-left: 24em;
}

.ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
	padding-left: 25.5em;
}

.ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
	padding-right: 24em;
}

.ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
	padding-right: 25.5em;
}

.ql-editor .ql-indent-9:not(.ql-direction-rtl) {
	padding-left: 27em;
}

.ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
	padding-left: 28.5em;
}

.ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
	padding-right: 27em;
}

.ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
	padding-right: 28.5em;
}

.ql-editor .ql-video {
	display: block;
	max-width: 100%;
}

.ql-editor .ql-video.ql-align-center {
	margin: 0 auto;
}

.ql-editor .ql-video.ql-align-right {
	margin: 0 0 0 auto;
}

.ql-editor .ql-bg-black {
	background-color: #000;
}

.ql-editor .ql-bg-red {
	background-color: #e60000;
}

.ql-editor .ql-bg-orange {
	background-color: #f90;
}

.ql-editor .ql-bg-yellow {
	background-color: #ff0;
}

.ql-editor .ql-bg-green {
	background-color: #008a00;
}

.ql-editor .ql-bg-blue {
	background-color: color('primary');
}

.ql-editor .ql-bg-purple {
	background-color: #93f;
}

.ql-editor .ql-color-white {
	color: #fff;
}

.ql-editor .ql-color-red {
	color: #e60000;
}

.ql-editor .ql-color-orange {
	color: #f90;
}

.ql-editor .ql-color-yellow {
	color: #ff0;
}

.ql-editor .ql-color-green {
	color: #008a00;
}

.ql-editor .ql-color-blue {
	color: color('primary');
}

.ql-editor .ql-color-purple {
	color: #93f;
}

.ql-editor .ql-font-serif {
	font-family: Georgia, Times New Roman, serif;
}

.ql-editor .ql-font-monospace {
	font-family: Monaco, Courier New, monospace;
}

.ql-editor .ql-size-small {
	font-size: 0.75em;
}

.ql-editor .ql-size-large {
	font-size: 1.5em;
}

.ql-editor .ql-size-huge {
	font-size: 2.5em;
}

.ql-editor .ql-direction-rtl {
	direction: rtl;
	text-align: inherit;
}

.ql-editor .ql-align-center {
	text-align: center;
}

.ql-editor .ql-align-justify {
	text-align: justify;
}

.ql-editor .ql-align-right {
	text-align: right;
}

.ql-container {
	position: relative;
	box-sizing: border-box;
	height: 100%;
	margin: 0px;
	font-family: Helvetica, Arial, sans-serif;
	font-size: 13px;
}

.ql-container.ql-disabled {
	.ql-tooltip {
		visibility: hidden;
	}

	.ql-editor {
		ul[data-checked] {
			> li {
				&:before {
					pointer-events: none;
				}
			}
		}
	}
}

.ql-clipboard {
	position: absolute;
	top: 50%;
	left: -100000px;
	height: 1px;
	overflow-y: hidden;

	p {
		margin: 0;
		padding: 0;
	}
}

.ql-editor {
	box-sizing: border-box;
	height: 100%;
	padding: 16px 20px 48px;
	overflow-y: auto;
	outline: none;
	font-size: 16px;
	line-height: 1.42;
	text-align: left;
	word-wrap: break-word;
	white-space: pre-wrap;
	tab-size: 4;

	> * {
		cursor: text;
	}

	p {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	ol {
		margin: 0;
		padding: 0;
		padding-left: 1.5em;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

		> li {
			list-style-type: none;
		}

		li {
			counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-0;

			&:not(.ql-direction-rtl) {
				padding-left: 1.5em;
			}

			&:before {
				content: counter(list-0, decimal) '. ';
			}
		}

		li.ql-direction-rtl {
			padding-right: 1.5em;
		}

		li.ql-indent-1 {
			counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-1;

			&:before {
				content: counter(list-1, lower-alpha) '. ';
			}
		}

		li.ql-indent-2 {
			counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-2;

			&:before {
				content: counter(list-2, lower-roman) '. ';
			}
		}

		li.ql-indent-3 {
			counter-reset: list-4 list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-3;

			&:before {
				content: counter(list-3, decimal) '. ';
			}
		}

		li.ql-indent-4 {
			counter-reset: list-5 list-6 list-7 list-8 list-9;
			counter-increment: list-4;

			&:before {
				content: counter(list-4, lower-alpha) '. ';
			}
		}

		li.ql-indent-5 {
			counter-reset: list-6 list-7 list-8 list-9;
			counter-increment: list-5;

			&:before {
				content: counter(list-5, lower-roman) '. ';
			}
		}

		li.ql-indent-6 {
			counter-reset: list-7 list-8 list-9;
			counter-increment: list-6;

			&:before {
				content: counter(list-6, decimal) '. ';
			}
		}

		li.ql-indent-7 {
			counter-reset: list-8 list-9;
			counter-increment: list-7;

			&:before {
				content: counter(list-7, lower-alpha) '. ';
			}
		}

		li.ql-indent-8 {
			counter-reset: list-9;
			counter-increment: list-8;

			&:before {
				content: counter(list-8, lower-roman) '. ';
			}
		}

		li.ql-indent-9 {
			counter-increment: list-9;

			&:before {
				content: counter(list-9, decimal) '. ';
			}
		}
	}

	ul {
		margin: 0;
		padding: 0;
		padding-left: 1.5em;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;

		> li {
			list-style-type: none;

			&:before {
				content: '\2022';
			}
		}

		li {
			&:not(.ql-direction-rtl) {
				padding-left: 1.5em;
			}
		}

		li.ql-direction-rtl {
			padding-right: 1.5em;
		}
	}

	pre {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	blockquote {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	h1 {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	h2 {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	h3 {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	h4 {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	h5 {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	h6 {
		margin: 0;
		padding: 0;
		counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
	}

	ul[data-checked='true'] {
		pointer-events: none;

		> li {
			* {
				pointer-events: all;
			}

			&:before {
				content: '\2611';
				color: #777;
				cursor: pointer;
				pointer-events: all;
			}
		}
	}

	ul[data-checked='false'] {
		pointer-events: none;

		> li {
			* {
				pointer-events: all;
			}

			&:before {
				content: '\2610';
				color: #777;
				cursor: pointer;
				pointer-events: all;
			}
		}
	}

	li {
		&:before {
			display: inline-block;
			width: 1.2em;
			white-space: nowrap;
		}

		&:not(.ql-direction-rtl) {
			&:before {
				margin-right: 0.3em;
				margin-left: -1.5em;
				text-align: right;
			}
		}
	}

	li.ql-direction-rtl {
		&:before {
			margin-right: -1.5em;
			margin-left: 0.3em;
		}
	}

	.ql-indent-1 {
		&:not(.ql-direction-rtl) {
			padding-left: 3em;
		}
	}

	li.ql-indent-1 {
		&:not(.ql-direction-rtl) {
			padding-left: 4.5em;
		}
	}

	.ql-indent-1.ql-direction-rtl.ql-align-right {
		padding-right: 3em;
	}

	li.ql-indent-1.ql-direction-rtl.ql-align-right {
		padding-right: 4.5em;
	}

	.ql-indent-2 {
		&:not(.ql-direction-rtl) {
			padding-left: 6em;
		}
	}

	li.ql-indent-2 {
		&:not(.ql-direction-rtl) {
			padding-left: 7.5em;
		}
	}

	.ql-indent-2.ql-direction-rtl.ql-align-right {
		padding-right: 6em;
	}

	li.ql-indent-2.ql-direction-rtl.ql-align-right {
		padding-right: 7.5em;
	}

	.ql-indent-3 {
		&:not(.ql-direction-rtl) {
			padding-left: 9em;
		}
	}

	li.ql-indent-3 {
		&:not(.ql-direction-rtl) {
			padding-left: 10.5em;
		}
	}

	.ql-indent-3.ql-direction-rtl.ql-align-right {
		padding-right: 9em;
	}

	li.ql-indent-3.ql-direction-rtl.ql-align-right {
		padding-right: 10.5em;
	}

	.ql-indent-4 {
		&:not(.ql-direction-rtl) {
			padding-left: 12em;
		}
	}

	li.ql-indent-4 {
		&:not(.ql-direction-rtl) {
			padding-left: 13.5em;
		}
	}

	.ql-indent-4.ql-direction-rtl.ql-align-right {
		padding-right: 12em;
	}

	li.ql-indent-4.ql-direction-rtl.ql-align-right {
		padding-right: 13.5em;
	}

	.ql-indent-5 {
		&:not(.ql-direction-rtl) {
			padding-left: 15em;
		}
	}

	li.ql-indent-5 {
		&:not(.ql-direction-rtl) {
			padding-left: 16.5em;
		}
	}

	.ql-indent-5.ql-direction-rtl.ql-align-right {
		padding-right: 15em;
	}

	li.ql-indent-5.ql-direction-rtl.ql-align-right {
		padding-right: 16.5em;
	}

	.ql-indent-6 {
		&:not(.ql-direction-rtl) {
			padding-left: 18em;
		}
	}

	li.ql-indent-6 {
		&:not(.ql-direction-rtl) {
			padding-left: 19.5em;
		}
	}

	.ql-indent-6.ql-direction-rtl.ql-align-right {
		padding-right: 18em;
	}

	li.ql-indent-6.ql-direction-rtl.ql-align-right {
		padding-right: 19.5em;
	}

	.ql-indent-7 {
		&:not(.ql-direction-rtl) {
			padding-left: 21em;
		}
	}

	li.ql-indent-7 {
		&:not(.ql-direction-rtl) {
			padding-left: 22.5em;
		}
	}

	.ql-indent-7.ql-direction-rtl.ql-align-right {
		padding-right: 21em;
	}

	li.ql-indent-7.ql-direction-rtl.ql-align-right {
		padding-right: 22.5em;
	}

	.ql-indent-8 {
		&:not(.ql-direction-rtl) {
			padding-left: 24em;
		}
	}

	li.ql-indent-8 {
		&:not(.ql-direction-rtl) {
			padding-left: 25.5em;
		}
	}

	.ql-indent-8.ql-direction-rtl.ql-align-right {
		padding-right: 24em;
	}

	li.ql-indent-8.ql-direction-rtl.ql-align-right {
		padding-right: 25.5em;
	}

	.ql-indent-9 {
		&:not(.ql-direction-rtl) {
			padding-left: 27em;
		}
	}

	li.ql-indent-9 {
		&:not(.ql-direction-rtl) {
			padding-left: 28.5em;
		}
	}

	.ql-indent-9.ql-direction-rtl.ql-align-right {
		padding-right: 27em;
	}

	li.ql-indent-9.ql-direction-rtl.ql-align-right {
		padding-right: 28.5em;
	}

	.ql-video {
		display: block;
		max-width: 100%;
	}

	.ql-video.ql-align-center {
		margin: 0 auto;
	}

	.ql-video.ql-align-right {
		margin: 0 0 0 auto;
	}

	.ql-bg-black {
		background-color: #000;
	}

	.ql-bg-red {
		background-color: #e60000;
	}

	.ql-bg-orange {
		background-color: #f90;
	}

	.ql-bg-yellow {
		background-color: #ff0;
	}

	.ql-bg-green {
		background-color: #008a00;
	}

	.ql-bg-blue {
		background-color: color('primary');
	}

	.ql-bg-purple {
		background-color: #93f;
	}

	.ql-color-white {
		color: #fff;
	}

	.ql-color-red {
		color: #e60000;
	}

	.ql-color-orange {
		color: #f90;
	}

	.ql-color-yellow {
		color: #ff0;
	}

	.ql-color-green {
		color: #008a00;
	}

	.ql-color-blue {
		color: color('primary');
	}

	.ql-color-purple {
		color: #93f;
	}

	.ql-font-serif {
		font-family: Georgia, Times New Roman, serif;
	}

	.ql-font-monospace {
		font-family: Monaco, Courier New, monospace;
	}

	.ql-size-small {
		font-size: 0.75em;
	}

	.ql-size-large {
		font-size: 1.5em;
	}

	.ql-size-huge {
		font-size: 2.5em;
	}

	.ql-direction-rtl {
		text-align: inherit;
		direction: rtl;
	}

	.ql-align-center {
		text-align: center;
	}

	.ql-align-justify {
		text-align: justify;
	}

	.ql-align-right {
		text-align: right;
	}
}

.ql-toolbar {
	padding: 17px 20px;
	border-bottom: 1px solid color('seashell');

	&:after {
		content: '';
		display: table;
		clear: both;
	}

	button {
		display: inline-block;
		width: 28px;
		height: 24px;
		padding: 3px 5px;
		float: left;
		border: none;
		background: none;
		cursor: pointer;

		svg {
			height: 100%;
			float: left;
		}

		&:active {
			&:hover {
				outline: none;
			}
		}

		&:hover {
			color: color('primary');

			.ql-fill {
				fill: color('primary');
			}

			.ql-stroke.ql-fill {
				fill: color('primary');
			}

			.ql-stroke {
				stroke: color('primary');
			}

			.ql-stroke-miter {
				stroke: color('primary');
			}
		}

		&:focus {
			color: color('primary');

			.ql-fill {
				fill: color('primary');
			}

			.ql-stroke.ql-fill {
				fill: color('primary');
			}

			.ql-stroke {
				stroke: color('primary');
			}

			.ql-stroke-miter {
				stroke: color('primary');
			}
		}
	}

	input.ql-image[type='file'] {
		display: none;
	}

	button.ql-active {
		color: color('primary');

		.ql-fill {
			fill: color('primary');
		}

		.ql-stroke.ql-fill {
			fill: color('primary');
		}

		.ql-stroke {
			stroke: color('primary');
		}

		.ql-stroke-miter {
			stroke: color('primary');
		}
	}

	.ql-picker-label {
		&:hover {
			color: color('primary');

			.ql-fill {
				fill: color('primary');
			}

			.ql-stroke.ql-fill {
				fill: color('primary');
			}

			.ql-stroke {
				stroke: color('primary');
			}

			.ql-stroke-miter {
				stroke: color('primary');
			}
		}
	}

	.ql-picker-label.ql-active {
		color: color('primary');

		.ql-fill {
			fill: color('primary');
		}

		.ql-stroke.ql-fill {
			fill: color('primary');
		}

		.ql-stroke {
			stroke: color('primary');
		}

		.ql-stroke-miter {
			stroke: color('primary');
		}
	}

	.ql-picker-item {
		&:hover {
			color: color('primary');

			.ql-fill {
				fill: color('primary');
			}

			.ql-stroke.ql-fill {
				fill: color('primary');
			}

			.ql-stroke {
				stroke: color('primary');
			}

			.ql-stroke-miter {
				stroke: color('primary');
			}
		}
	}

	.ql-picker-item.ql-selected {
		color: color('primary');

		.ql-fill {
			fill: color('primary');
		}

		.ql-stroke.ql-fill {
			fill: color('primary');
		}

		.ql-stroke {
			stroke: color('primary');
		}

		.ql-stroke-miter {
			stroke: color('primary');
		}
	}
}

.ql-snow {
	box-sizing: border-box;

	.ql-toolbar {
		&:after {
			content: '';
			display: table;
			clear: both;
		}

		button {
			display: inline-block;
			width: 28px;
			height: 24px;
			padding: 3px 5px;
			float: left;
			border: none;
			background: none;
			cursor: pointer;

			svg {
				height: 100%;
				float: left;
			}

			&:active {
				&:hover {
					outline: none;
				}
			}

			&:hover {
				color: color('primary');

				.ql-fill {
					fill: color('primary');
				}

				.ql-stroke.ql-fill {
					fill: color('primary');
				}

				.ql-stroke {
					stroke: color('primary');
				}

				.ql-stroke-miter {
					stroke: color('primary');
				}
			}

			&:focus {
				color: color('primary');

				.ql-fill {
					fill: color('primary');
				}

				.ql-stroke.ql-fill {
					fill: color('primary');
				}

				.ql-stroke {
					stroke: color('primary');
				}

				.ql-stroke-miter {
					stroke: color('primary');
				}
			}
		}

		input.ql-image[type='file'] {
			display: none;
		}

		button.ql-active {
			color: color('primary');

			.ql-fill {
				fill: color('primary');
			}

			.ql-stroke.ql-fill {
				fill: color('primary');
			}

			.ql-stroke {
				stroke: color('primary');
			}

			.ql-stroke-miter {
				stroke: color('primary');
			}
		}

		.ql-picker-label {
			&:hover {
				color: color('primary');

				.ql-fill {
					fill: color('primary');
				}

				.ql-stroke.ql-fill {
					fill: color('primary');
				}

				.ql-stroke {
					stroke: color('primary');
				}

				.ql-stroke-miter {
					stroke: color('primary');
				}
			}
		}

		.ql-picker-label.ql-active {
			color: color('primary');

			.ql-fill {
				fill: color('primary');
			}

			.ql-stroke.ql-fill {
				fill: color('primary');
			}

			.ql-stroke {
				stroke: color('primary');
			}

			.ql-stroke-miter {
				stroke: color('primary');
			}
		}

		.ql-picker-item {
			&:hover {
				color: color('primary');

				.ql-fill {
					fill: color('primary');
				}

				.ql-stroke.ql-fill {
					fill: color('primary');
				}

				.ql-stroke {
					stroke: color('primary');
				}

				.ql-stroke-miter {
					stroke: color('primary');
				}
			}
		}

		.ql-picker-item.ql-selected {
			color: color('primary');

			.ql-fill {
				fill: color('primary');
			}

			.ql-stroke.ql-fill {
				fill: color('primary');
			}

			.ql-stroke {
				stroke: color('primary');
			}

			.ql-stroke-miter {
				stroke: color('primary');
			}
		}
	}

	* {
		box-sizing: border-box;
	}

	.ql-hidden {
		display: none;
	}

	.ql-out-bottom {
		visibility: hidden;
	}

	.ql-out-top {
		visibility: hidden;
	}

	.ql-tooltip {
		position: absolute;
		padding: 5px 12px;
		transform: translateY(10px);
		border: 1px solid #ccc;
		background-color: #fff;
		box-shadow: 0px 0px 5px #ddd;
		color: #444;
		white-space: nowrap;

		a {
			line-height: 26px;
			text-decoration: none;
			cursor: pointer;
		}

		&:before {
			content: 'Visit URL:';
			margin-right: 8px;
			line-height: 26px;
		}

		input[type='text'] {
			display: none;
			width: 170px;
			height: 26px;
			margin: 0px;
			padding: 3px 5px;
			border: 1px solid #ccc;
			font-size: 13px;
		}

		a.ql-preview {
			display: inline-block;
			max-width: 200px;
			overflow-x: hidden;
			text-overflow: ellipsis;
			vertical-align: top;
		}

		a.ql-action {
			&:after {
				content: 'Edit';
				margin-left: 16px;
				padding-right: 8px;
				border-right: 1px solid #ccc;
			}
		}

		a.ql-remove {
			&:before {
				content: 'Remove';
				margin-left: 8px;
			}
		}
	}

	.ql-tooltip.ql-flip {
		transform: translateY(-10px);
	}

	.ql-formats {
		display: inline-block;
		vertical-align: middle;

		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}

	.ql-stroke {
		stroke-width: 2;
		stroke-linecap: round;
		stroke: #444;
		fill: none;
		stroke-linejoin: round;
	}

	.ql-stroke-miter {
		stroke-width: 2;
		stroke: #444;
		fill: none;
		stroke-miterlimit: 10;
	}

	.ql-fill {
		fill: #444;
	}

	.ql-stroke.ql-fill {
		fill: #444;
	}

	.ql-empty {
		fill: none;
	}

	.ql-even {
		fill-rule: evenodd;
	}

	.ql-thin {
		stroke-width: 1;
	}

	.ql-stroke.ql-thin {
		stroke-width: 1;
	}

	.ql-transparent {
		opacity: 0.4;
	}

	.ql-direction {
		svg {
			&:last-child {
				display: none;
			}
		}
	}

	.ql-direction.ql-active {
		svg {
			&:last-child {
				display: inline;
			}

			&:first-child {
				display: none;
			}
		}
	}

	.ql-editor {
		h1 {
			font-size: 2em;
		}

		h2 {
			font-size: 1.5em;
		}

		h3 {
			font-size: 1.17em;
		}

		h4 {
			font-size: 1em;
		}

		h5 {
			font-size: 0.83em;
		}

		h6 {
			font-size: 0.67em;
		}

		a {
			text-decoration: underline;
		}

		blockquote {
			margin-top: 5px;
			margin-bottom: 5px;
			padding-left: 16px;
			border-left: 4px solid #ccc;
		}

		code {
			padding: 2px 4px;
			border-radius: 3px;
			background-color: #f0f0f0;
			font-size: 85%;
		}

		pre {
			margin-top: 5px;
			margin-bottom: 5px;
			padding: 5px 10px;
			border-radius: 3px;
			background-color: #f0f0f0;
			white-space: pre-wrap;
		}

		pre.ql-syntax {
			overflow: visible;
			background-color: #23241f;
			color: #f8f8f2;
		}

		img {
			max-width: 100%;
		}
	}

	.ql-picker {
		display: inline-block;
		position: relative;
		height: 24px;
		float: left;
		color: #444;
		font-size: 14px;
		font-weight: 500;
		vertical-align: middle;

		&:not(.ql-color-picker) {
			&:not(.ql-icon-picker) {
				svg {
					position: absolute;
					top: 50%;
					right: 0;
					width: 18px;
					margin-top: -9px;
				}
			}
		}
	}

	.ql-picker-label {
		display: inline-block;
		position: relative;
		width: 100%;
		height: 100%;
		padding-right: 2px;
		padding-left: 8px;
		cursor: pointer;

		&:before {
			display: inline-block;
			line-height: 22px;
		}
	}

	.ql-picker-options {
		display: none;
		position: absolute;
		min-width: 100%;
		padding: 4px 8px;
		background-color: #fff;
		white-space: nowrap;

		.ql-picker-item {
			display: block;
			padding-top: 5px;
			padding-bottom: 5px;
			cursor: pointer;
		}
	}

	.ql-picker.ql-expanded {
		.ql-picker-label {
			z-index: 2;
			color: #ccc;

			.ql-fill {
				fill: #ccc;
			}

			.ql-stroke {
				stroke: #ccc;
			}
		}

		.ql-picker-options {
			display: block;
			z-index: 1;
			top: 100%;
			margin-top: -1px;
		}
	}

	.ql-color-picker {
		width: 28px;

		.ql-picker-label {
			padding: 2px 4px;

			svg {
				right: 4px;
			}
		}

		.ql-picker-options {
			width: 152px;
			padding: 3px 5px;
		}

		.ql-picker-item {
			width: 16px;
			height: 16px;
			margin: 2px;
			padding: 0px;
			float: left;
			border: 1px solid transparent;
		}
	}

	.ql-icon-picker {
		width: 28px;

		.ql-picker-label {
			padding: 2px 4px;

			svg {
				right: 4px;
			}
		}

		.ql-picker-options {
			padding: 4px 0px;
		}

		.ql-picker-item {
			width: 24px;
			height: 24px;
			padding: 2px 4px;
		}
	}

	.ql-picker.ql-header {
		width: 98px;

		.ql-picker-label[data-label] {
			&:not([data-label='']) {
				&:before {
					content: attr(data-label);
				}
			}
		}

		.ql-picker-item[data-label] {
			&:not([data-label='']) {
				&:before {
					content: attr(data-label);
				}
			}
		}

		.ql-picker-label {
			&:before {
				content: 'Normal';
			}
		}

		.ql-picker-item {
			&:before {
				content: 'Normal';
			}
		}

		.ql-picker-label[data-value='1'] {
			&:before {
				content: 'Heading 1';
			}
		}

		.ql-picker-item[data-value='1'] {
			&:before {
				content: 'Heading 1';
				font-size: 2em;
			}
		}

		.ql-picker-label[data-value='2'] {
			&:before {
				content: 'Heading 2';
			}
		}

		.ql-picker-item[data-value='2'] {
			&:before {
				content: 'Heading 2';
				font-size: 1.5em;
			}
		}

		.ql-picker-label[data-value='3'] {
			&:before {
				content: 'Heading 3';
			}
		}

		.ql-picker-item[data-value='3'] {
			&:before {
				content: 'Heading 3';
				font-size: 1.17em;
			}
		}

		.ql-picker-label[data-value='4'] {
			&:before {
				content: 'Heading 4';
			}
		}

		.ql-picker-item[data-value='4'] {
			&:before {
				content: 'Heading 4';
				font-size: 1em;
			}
		}

		.ql-picker-label[data-value='5'] {
			&:before {
				content: 'Heading 5';
			}
		}

		.ql-picker-item[data-value='5'] {
			&:before {
				content: 'Heading 5';
				font-size: 0.83em;
			}
		}

		.ql-picker-label[data-value='6'] {
			&:before {
				content: 'Heading 6';
			}
		}

		.ql-picker-item[data-value='6'] {
			&:before {
				content: 'Heading 6';
				font-size: 0.67em;
			}
		}
	}

	.ql-picker.ql-font {
		width: 108px;

		.ql-picker-label[data-label] {
			&:not([data-label='']) {
				&:before {
					content: attr(data-label);
				}
			}
		}

		.ql-picker-item[data-label] {
			&:not([data-label='']) {
				&:before {
					content: attr(data-label);
				}
			}
		}

		.ql-picker-label {
			&:before {
				content: 'Sans Serif';
			}
		}

		.ql-picker-item {
			&:before {
				content: 'Sans Serif';
			}
		}

		.ql-picker-label[data-value='serif'] {
			&:before {
				content: 'Serif';
			}
		}

		.ql-picker-item[data-value='serif'] {
			&:before {
				content: 'Serif';
				font-family: Georgia, Times New Roman, serif;
			}
		}

		.ql-picker-label[data-value='monospace'] {
			&:before {
				content: 'Monospace';
			}
		}

		.ql-picker-item[data-value='monospace'] {
			&:before {
				content: 'Monospace';
				font-family: Monaco, Courier New, monospace;
			}
		}
	}

	.ql-picker.ql-size {
		width: 98px;

		.ql-picker-label[data-label] {
			&:not([data-label='']) {
				&:before {
					content: attr(data-label);
				}
			}
		}

		.ql-picker-item[data-label] {
			&:not([data-label='']) {
				&:before {
					content: attr(data-label);
				}
			}
		}

		.ql-picker-label {
			&:before {
				content: 'Normal';
			}
		}

		.ql-picker-item {
			&:before {
				content: 'Normal';
			}
		}

		.ql-picker-label[data-value='small'] {
			&:before {
				content: 'Small';
			}
		}

		.ql-picker-item[data-value='small'] {
			&:before {
				content: 'Small';
				font-size: 10px;
			}
		}

		.ql-picker-label[data-value='large'] {
			&:before {
				content: 'Large';
			}
		}

		.ql-picker-item[data-value='large'] {
			&:before {
				content: 'Large';
				font-size: 18px;
			}
		}

		.ql-picker-label[data-value='huge'] {
			&:before {
				content: 'Huge';
			}
		}

		.ql-picker-item[data-value='huge'] {
			&:before {
				content: 'Huge';
				font-size: 32px;
			}
		}
	}

	.ql-color-picker.ql-background {
		.ql-picker-item {
			background-color: #fff;
		}
	}

	.ql-color-picker.ql-color {
		.ql-picker-item {
			background-color: #000;
		}
	}

	.ql-tooltip.ql-editing {
		a.ql-preview {
			display: none;
		}

		a.ql-remove {
			display: none;
		}

		input[type='text'] {
			display: inline-block;
		}

		a.ql-action {
			&:after {
				content: 'Save';
				padding-right: 0px;
				border-right: 0px;
			}
		}
	}

	.ql-tooltip[data-mode='link'] {
		&:before {
			content: 'Enter link:';
		}
	}

	.ql-tooltip[data-mode='formula'] {
		&:before {
			content: 'Enter formula:';
		}
	}

	.ql-tooltip[data-mode='video'] {
		&:before {
			content: 'Enter video:';
		}
	}

	a {
		color: color('primary');
	}
}

.ql-toolbar.ql-snow {
	box-sizing: border-box;

	.ql-formats {
		margin-right: 15px;
	}

	.ql-picker-label {
		border: 1px solid transparent;
	}

	.ql-picker-options {
		border: 1px solid transparent;
		box-shadow: rgba(0, 0, 0, 0.2) 0 2px 8px;
	}

	.ql-picker.ql-expanded {
		.ql-picker-label {
			border-color: #ccc;
		}

		.ql-picker-options {
			border-color: #ccc;
		}
	}

	.ql-color-picker {
		.ql-picker-item.ql-selected {
			border-color: #000;
		}

		.ql-picker-item {
			&:hover {
				border-color: #000;
			}
		}
	}

	+ {
		.ql-container.ql-snow {
			border-top: 0px;
		}
	}
}

@media (pointer: coarse) {
	.ql-snow.ql-toolbar {
		button {
			&:hover {
				&:not(.ql-active) {
					color: #444;

					.ql-fill {
						fill: #444;
					}

					.ql-stroke.ql-fill {
						fill: #444;
					}

					.ql-stroke {
						stroke: #444;
					}

					.ql-stroke-miter {
						stroke: #444;
					}
				}
			}
		}
	}

	.ql-snow {
		.ql-toolbar {
			button {
				&:hover {
					&:not(.ql-active) {
						color: #444;

						.ql-fill {
							fill: #444;
						}

						.ql-stroke.ql-fill {
							fill: #444;
						}

						.ql-stroke {
							stroke: #444;
						}

						.ql-stroke-miter {
							stroke: #444;
						}
					}
				}
			}
		}
	}
}
