// I wonder if it would be better this was created without importing variables 🤔
@import 'variables';

.table {
	display: block;
	width: 100%;
	&__content {
		position: relative;
		padding-top: 6px;
		margin: 10px;

		&-inner {
			&.is-virtual {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				overflow-anchor: none;
			}
		}
	}

	&__content-inner {
		& > *:first-child {
			.table__row {
				&.isInline {
					border-color: transparent;
				}
			}
		}
	}

	&__header-row {
		display: flex;
		z-index: 2;
		top: 0;
		flex-grow: 1;
		align-items: flex-end;
		padding: 0 20px;
		border: 1px solid transparent;
		overflow-anchor: none;
		color: color('grey');
		font-size: 12px;

		&.isSticky {
			@include media('xl') {
				position: sticky;
				border-width: 0;

				&:after {
					content: ' ';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transition: opacity 0.1s;
					border-top: 1px solid transparentize(color('text'), 0.95);
					border-bottom-right-radius: 20px;
					border-bottom-left-radius: 20px;
					opacity: 0;
					background: color('white');
					box-shadow: 0 4px 8px 0 transparentize(color('text'), 0.95);
				}

				&.isInline {
					top: 0;
					border: 0;
				}
			}
		}

		&.isScrolled {
			z-index: index('table-header');

			&:after {
				opacity: 1;
			}
		}

		&.isInline {
			min-width: auto;
			padding-right: 0;
			padding-left: 0;
			border-color: transparent;
		}

		& > * {
			position: relative;
			z-index: 1;
		}
	}

	&__row {
		display: flex;
		flex-grow: 1;
		align-items: center;
		margin-top: 12px;
		padding: 5px 20px;
		border-radius: 20px;
		background: color('white');
		@include shadow;

		&.isInline {
			min-width: auto;
			padding-right: 0;
			padding-left: 0;
			border-color: transparent;
		}

		&.is-flex-start {
			align-items: flex-start;
		}

		@include media('xxl') {
		}
	}

	&__row-before {
		& > div {
			&:empty {
				border-bottom: 0;
			}
		}
	}

	&__header-cell {
		margin: 6px 0;
		// color: color('grey-40');
		font-weight: 300;
	}

	&__cell {
		flex-basis: auto;
		flex-shrink: 0;
		font-size: 14px;
		line-height: 18px;

		&:first-child {
			padding-left: 0 !important;
		}

		&:last-child {
			padding-right: 0 !important;
		}

		&.is-grow {
			flex-grow: 1;
			flex-shrink: 0;
			min-width: 0;
		}
	}

	@include media-max('xxl') {
		overflow: auto;
	}
}

.table-fake-row {
	.table__row {
		align-items: flex-start;
	}

	.table-fake-row__column + .table-fake-row__column {
		position: relative;

		&:before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			width: calc(100% + 40px);
			height: 1px;
			// background: color('white-two');
		}

		&--end {
			&:before {
				width: calc(100%);
			}
		}

		&--last-column {
			&:before {
				width: calc(100% + 20px);
			}
		}
	}

	.table-fake-row__column {
		display: flex;
		align-items: center;
		height: 56px;
		padding: 10px 0;

		&.is {
			&-first {
				height: 51px;
				padding-bottom: 10px;
			}

			&-last {
				height: 51px;
				padding-top: 10px;
			}

			&-single {
				height: auto;
				min-height: 57px;
			}
		}
	}
}

.table-filter-info {
	padding: 30px 0 15px;

	&__text {
		// @include font('small');

		display: flex;
		align-items: center;
		padding: 0 0 15px;
		// color: color('grey-50');

		strong {
			// color: color('text');
			font-weight: 500;
		}
	}

	&__clear {
		margin-top: 2px;
		margin-left: 10px;

		svg {
			// fill: color('blue');
		}
	}

	&__empty {
		// @include font('small');

		position: relative;
		padding: 15px 0;
		// color: color('grey-70');

		&:after {
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			max-width: 500px;
			height: 1px;
			// background: transparentize(color('text'), 0.9);
		}
	}
}
