.tooltip {
	display: block;
	position: fixed;
	z-index: index('first');
	max-width: 220px;
	padding: 18px 20px;
	border-radius: 15px;
	background: color('white');
	box-shadow: 0px 0px 14px transparentize(color('black'), 0.95),
		2px 7px 14px transparentize(color('black'), 0.95);
	color: color('black');
	font-size: 13px;
	line-height: 20px;
	text-align: left;
	white-space: initial;
	cursor: text;
	pointer-events: none;

	&:before {
		content: '';
		position: absolute;
	}

	&--position {
		&-right {
			&:before {
				@include triangle(14px, 14px, 'left', color('white'));

				z-index: -1;
				top: 50%;
				left: -10px;
				transform: translateY(-50%);
			}
		}
		&-left {
			&:before {
				@include triangle(14px, 14px, 'right', color('white'));

				z-index: -1;
				top: 50%;
				right: -10px;
				transform: translateY(-50%);
			}
		}
	}
}
