.loading {
	display: inline-block;
	position: relative;
	pointer-events: none;
	user-select: none;

	&:before {
		content: '';
		position: absolute;
		top: calc(50% - 15px);
		left: calc(50% - 15px);
		width: 30px;
		height: 30px;
		transform: translate(-50%, -50%);
		animation: spin 0.8s infinite linear;
		border: 3px solid color('primary');
		border-radius: 50%;
		border-bottom-color: transparent;
		border-left-color: transparent;
	}

	&__inner {
		opacity: 0.3;
	}

	&.isBlock {
		display: block;
	}

	&--center {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}
