.badge {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	position: relative;
	overflow: hidden;
	color: color('primary');
	flex: 0 0 auto;

	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: color('primary');
		opacity: .1;
	}
	
	&--small {
		width: 20px;
		height: 20px;
		font-size: 13px;
		line-height: 13px;
	}
}
