.hidden-input {
	display: block;
	position: absolute;
	z-index: -1;
	bottom: 0;
	left: 0;
	width: 100%;
	opacity: 0;
	pointer-events: none;
}

.form {
	&-footer {
		margin-top: 30px;
	}
}
