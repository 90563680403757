.btn {
	display: inline-block;
	position: relative;
	transition: color $time-transition, background $time-transition, transform $time-transition,
		box-shadow $time-transition;
	border: 1px solid;
	border-radius: 22px;
	text-align: center;

	&:active {
		transform: translateY(1px);
	}

	// Colors
	&--color {
		&-primary {
			border-color: color('primary');
			background: color('primary');
			color: color('white');

			&:before {
				content: '';
				position: absolute;
				bottom: 0;
				left: 50%;
				width: 80%;
				height: 80%;
				transform: translate(-50%, 50%);
				border-radius: 22px;
				opacity: 0.3;
				// TODO: Consider if we need this background coloring. I think it looks odd. Try from multiple screens!
				background: color('primary');
				filter: blur(34px);
				pointer-events: none;
			}
		}

		&-secondary {
			border-color: color('primary');
			color: color('primary');
		}

		&-third {
			border-color: color('grey');
			color: color('grey');
		}

		&-transparent {
			border-color: transparent;
			color: color('black');
			font-weight: 500;
		}
	}

	// Sizes
	&--size {
		&-small {
			padding: 10px 15px;
			font-size: 11px;
		}

		&-default {
			padding: 14px 23px;
			font-size: 11px;
			line-height: 11px;
		}

		&-big {
			padding: 16px 33px;
			font-size: 15px;
			line-height: 15px;
		}
	}

	// Decorations
	&--decoration {
		&-arrow {
			display: inline-flex;
			align-items: center;

			span {
				width: 56px;
				text-align: left;
			}

			.icon {
				width: 14px;
				height: 6px;
			}

			> * + * {
				padding-left: 10px;
			}
		}

		&-icon {
			display: inline-flex;
			align-items: center;

			> * + * {
				padding-left: 12px;
			}
		}
	}

	&--block {
		display: block;
		width: 100%;
	}

	&[disabled],
	&.isDisabled {
		opacity: 0.4;
		pointer-events: none;
	}
}
