.link {
	display: inline-block;
	transition: color $time-transition;
	cursor: pointer;
	user-select: none;

	&--color {
		&-primary {
			color: color('primary');
		}
	}

	&--size {
		&-tiny {
			font-size: 13px;
		}
	}
}
