:root {
	--color-primary: #56418e;
	--color-primary-lighten: #b7aadb;
	--color-background: $white;
}

$colors: (
	'primary': var(--color-primary),
	'primary light': var(--color-primary-lighten),
	'text': #101931,
	'grey': #78828d,
	'light grey': #b6bbc2,
	'porcelain': #f7f8f9,
	'mercury': #e5e5e5,
	'oxford blue': #353c51,
	'white': #fff,
	'black': #000,
	'mine shaft': #333,
	'transparent-grey': #f4f4f4,
	'error': #de350b,
	'warning': #ffb300,
	'success': #36b37e,
	'seashell': #f1f1f1,
	'analytics primary': #84aff0,
	'analytics secondary': #56418e,
	'analytics orange': #ffa794,
	'analytics gradient': #1f3479 #7e4a9d #d25899 #ff5553,
	'analytics bar primary fill': #ebf5ff,
	'analytics bar primary border': #84aff0,
	'analytics bar secondary fill': #eee8ff,
	'analytics bar secondary border': #56418e,
	'fasttrack performance': #b9679f,
	'fasttrack competencies': #81be8f,
	'fasttrack network': #df8327,
	'fasttrack culture': #e34159,
	'fasttrack collaboration': #136c9b,
	'fasttrack rules': #54c2ee,
);
