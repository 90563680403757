.dp {
	&-popup {
		padding: 20px;
		border: 0;
		border-radius: 24px;
		background: color('white');
		box-shadow: 0px 0px 14px transparentize(color('black'), 0.96),
			2px 7px 14px transparentize(color('black'), 0.98);
	}

	&-nav {
		&-header {
			position: static !important;
			transform: none !important;

			&-btn {
				box-sizing: border-box;
				padding: 11px 16px;
				border: 1px solid color('primary');
				border-radius: 16px;
			}
		}

		&-btns {
			&-container {
				display: flex !important;
				position: static !important;
				box-sizing: border-box;
				align-items: center;
				justify-content: space-between;
				padding: 7px 16px;
				transform: none !important;
				border: 1px solid color('primary');
				border-radius: 16px;
			}
		}
	}

	&-weekdays {
		margin: 0 !important;
	}

	&-calendar {
		&-nav {
			&-right,
			&-left {
				color: color('primary');
				text-align: center;

				&:before {
					border-width: 1px 1px 0 0 !important;
				}
			}

			&-container {
				display: flex;
				justify-content: space-between;
				height: unset !important;
				margin-bottom: 17px;
				border: 0 !important;
			}
		}

		&-day {
			width: 36px !important;
			height: 36px !important;
			border-radius: 8px;
			font-size: 12px;
			line-height: 12px;
			text-align: center;

			&:hover {
				background: transparentize(color('black'), 0.9);
			}

			&.dp-current-day {
				background: transparentize(color('black'), 0.95);
			}

			&.dp-selected {
				background: color('primary');
				color: color('white');
			}
		}

		&-wrapper {
			border: 0 !important;
		}

		&-month {
			border-radius: 8px;
			font-size: 12px;
			line-height: 12px;
			text-align: center;

			&.dp-selected {
				background: color('primary') !important;
			}

			@include hover {
				background: transparentize(color('black'), 0.9);
			}
		}

		&-weekday {
			display: inline-flex !important;
			align-items: center;
			justify-content: center;
			width: 36px !important;
			height: 36px !important;
			border: 0 !important;
			color: color('mine shaft');
			font-size: 12px;
			font-weight: 600;
			line-height: 12px;
		}
	}
}
