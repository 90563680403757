.modal {
	display: none;
	position: fixed;
	z-index: index('modal');
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	overflow-x: hidden;
	overflow-y: auto;
	transition: opacity $time-transition, transform $time-transition;
	outline: 0;

	&.fade {
		transform: translateY(40px);
		opacity: 0;
	}

	&.show {
		transform: translateY(0);
		opacity: 1;
	}

	&.d-block {
		display: block;
	}

	&-open {
		overflow: hidden;
	}

	&-dialog {
		position: relative;
		width: auto;
		margin: 20px;
		pointer-events: none;

		&-scrollable {
			display: flex;
			max-height: calc(100vh - 40px);
		}

		&-centered {
			display: flex;
			align-items: center;
			min-height: calc(100vh - 40px);

			&:before {
				content: '';
				display: block;
				height: calc(100vh - 40px);
				height: min-content;
			}

			&.modal-dialog-scrollable {
				flex-direction: column;
				justify-content: center;
				height: 100%;

				.modal-content {
					max-height: none;
				}

				&:before {
					content: none;
				}
			}
		}
	}

	&-content {
		width: 100%;
		outline: 0;

		.modal-dialog-scrollable & {
			min-height: calc(100vh - var(--vh-offset, 0px));
			overflow: hidden;
		}
	}

	&-backdrop {
		position: fixed;
		z-index: index('modal') !important;
		top: 0;
		left: 0;
		width: 100vw;
		height: calc(100vh - var(--vh-offset, 0px));
		transition: opacity $time-transition;
		background-color: transparentize(color('black'), 0.4);

		&.fade {
			opacity: 0;
		}

		&.show {
			opacity: 1;
		}
	}

	&-scrollbar {
		&-measure {
			position: absolute;
			top: -9999px;
			width: 50px;
			height: 50px;
			overflow: scroll;
		}
	}
}
