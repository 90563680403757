html {
	background: color('porcelain');
	color: color('text');
	@include font-family;
}

body {
	@include media('md') {
		overflow-y: auto;
	}
}

* {
	@include scrollbar(7px, color('primary'), (color('primary light')));
}
