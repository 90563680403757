:root {
	--font-primary: 'Sofia Pro';
}

$fonts: (
	'default': var(--font-primary),
);

@mixin font-family {
	font-family: var(--font-primary);
}

@function font($id) {
	@if map-has-key($fonts, $id) {
		@return map-get($fonts, $id);
	}

	@error 'Invalid index name: `#{$id}`.';
}
