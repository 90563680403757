// Start custom Angular Material theme
@import './assets/styles/libs/am-dynamic-theme';

@import './assets/styles/main';
@import 'variables';

html {
	background-color: var(--color-background);
}

app-root,
html,
body {
	height: 100%;
	width: 100%;
}

.highlight {
	display: inline-block;
	border: 0.2px solid lightgrey;
	color: blue;
	border-radius: 5px;
	padding: 0 2px;
	@include shadow;
}

.invisible {
	visibility: hidden;
}

.HW_frame_cont {
	margin-left: 62px;
}
