@mixin fontface($name, $file, $weight: normal, $style: normal) {
	@font-face {
		font-family: '#{$name}';
		font-style: $style;
		font-weight: $weight;
		src: url('/assets/fonts/#{$file}.eot'); // IE9+ Compat Modes
		// IE6-IE8
		src: url('/assets/fonts/#{$file}.eot?#iefix') format('embedded-opentype'),
			// Super modern browsers
				url('/assets/fonts/#{$file}.woff2') format('woff2'),
			// Modern browsers
				url('/assets/fonts/#{$file}.woff') format('woff'),
			// Safari, Android, iOS
				url('/assets/fonts/#{$file}.ttf') format('truetype'),
			// Legacy iOS
				url('/assets/fonts/#{$file}.svg?#webfont') format('svg');
	}
}

// Sofia Pro
@include fontface('Sofia Pro', 'sofia_pro_ultralight', 100);
@include fontface('Sofia Pro', 'sofia_pro_ultralight_italic', 100, italic);
@include fontface('Sofia Pro', 'sofia_pro_extralight', 200);
@include fontface('Sofia Pro', 'sofia_pro_extralight_italic', 200, italic);
@include fontface('Sofia Pro', 'sofia_pro_light', 300);
@include fontface('Sofia Pro', 'sofia_pro_light_italic', 300, italic);
@include fontface('Sofia Pro', 'sofia_pro_regular', 400);
@include fontface('Sofia Pro', 'sofia_pro_regular_italic', 400, italic);
@include fontface('Sofia Pro', 'sofia_pro_medium', 500);
@include fontface('Sofia Pro', 'sofia_pro_medium_italic', 500, italic);
@include fontface('Sofia Pro', 'sofia_pro_semi_bold', 600);
@include fontface('Sofia Pro', 'sofia_pro_semi_bold_italic', 600, italic);
@include fontface('Sofia Pro', 'sofia_pro_bold', 700);
@include fontface('Sofia Pro', 'sofia_pro_bold_italic', 700, italic);
@include fontface('Sofia Pro', 'sofia_pro_black', 900);
@include fontface('Sofia Pro', 'sofia_pro_black_italic', 900, italic);

// Nationalbank
@include fontface('Nationalbanken', 'Nationalbank-Regular', 400);
@include fontface('Nationalbanken', 'Nationalbank-Italic', 400, italic);
@include fontface('Nationalbanken', 'Nationalbank-Bold', 700);
@include fontface('Nationalbanken', 'Nationalbank-BoldItalic', 700, italic);

// KBH
@include fontface('KBH', 'KBH-Thin', 100);
@include fontface('KBH', 'KBH-ThinItalic', 100, italic);
@include fontface('KBH', 'KBH-Light', 200);
@include fontface('KBH', 'KBH-LightItalic', 200, italic);
@include fontface('KBH', 'KBH-Light', 300);
@include fontface('KBH', 'KBH-LightItalic', 300, italic);
@include fontface('KBH', 'KBH-Regular', 400);
@include fontface('KBH', 'KBH-Italic', 400, italic);
@include fontface('KBH', 'KBH-Medium', 500);
@include fontface('KBH', 'KBH-MediumItalic', 500, italic);
@include fontface('KBH', 'KBH-Semibold', 600);
@include fontface('KBH', 'KBH-SemiboldItalic', 600, italic);
@include fontface('KBH', 'KBH-Bold', 700);
@include fontface('KBH', 'KBH-BoldItalic', 700, italic);
@include fontface('KBH', 'KBH-Black', 900);
@include fontface('KBH', 'KBH-BlackItalic', 900, italic);

// Poppins
@include fontface('Poppins', 'Poppins-Thin', 100);
@include fontface('Poppins', 'Poppins-ThinItalic', 100, italic);
@include fontface('Poppins', 'Poppins-ExtraLight', 200);
@include fontface('Poppins', 'Poppins-ExtraLightItalic', 200, italic);
@include fontface('Poppins', 'Poppins-Light', 300);
@include fontface('Poppins', 'Poppins-LightItalic', 300, italic);
@include fontface('Poppins', 'Poppins-Regular', 400);
@include fontface('Poppins', 'Poppins-Italic', 400, italic);
@include fontface('Poppins', 'Poppins-Medium', 500);
@include fontface('Poppins', 'Poppins-MediumItalic', 500, italic);
@include fontface('Poppins', 'Poppins-SemiBold', 600);
@include fontface('Poppins', 'Poppins-SemiBoldItalic', 600, italic);
@include fontface('Poppins', 'Poppins-Bold', 700);
@include fontface('Poppins', 'Poppins-BoldItalic', 700, italic);
@include fontface('Poppins', 'Poppins-ExtraBold', 800);
@include fontface('Poppins', 'Poppins-ExtraBoldItalic', 800, italic);
@include fontface('Poppins', 'Poppins-Black', 900);
@include fontface('Poppins', 'Poppins-BlackItalic', 900, italic);

// Chainalysis
@include fontface('Chainalysis', 'chainalysis_regular', 400);

// Calibri
@include fontface('Calibri', 'calibri-regular', 400);
@include fontface('Calibri', 'calibri-bold', 700);
@include fontface('Calibri', 'calibri-italic', 400, italic);
@include fontface('Calibri', 'calibri-bold-italic', 700, italic);

// Cambria Math
@include fontface('Cambria Math', 'cambria-math', 400);

// Sweco
@include fontface('SwecoSans', 'SwecoSans-Regular', 400);
@include fontface('SwecoSans', 'SwecoSans-Medium', 500);
@include fontface('SwecoSans', 'SwecoSans-MediumItalic', 500, italic);
@include fontface('SwecoSans', 'SwecoSans-Italic', 400, italic);
@include fontface('SwecoSans', 'SwecoSans-Bold', 700);
@include fontface('SwecoSans', 'SwecoSans-BoldItalic', 700, italic);
