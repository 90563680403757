// Overwrites styles for mat-tab and mat-tab group
.mdc-tab--active,
.mdc-tab-indicator--active {
	color: color('primary');
}

// Overwrite styles for mat-slide-toggle after material.v15
.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__shadow {
	background-color: color('primary');
}

.mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled) .mdc-switch__track {
	&::after,
	&::before {
		background-color: color('primary') !important;
	}
}

.cdk-overlay-container {
	z-index: index('date-picker') !important; // Makes sure date-picker stays on top of other modals
}
