@mixin shadow(
	$offset-x: 0px,
	$offset-y: 0px,
	$shadow-blur: 9px,
	$stretching: 5px,
	$color: #363636,
	$opacity: 0.9
) {
	box-shadow: $offset-x $offset-y $shadow-blur $stretching transparentize($color, $opacity);
}

@mixin zoom-animation($duration: 1s, $delay: 0s) {
	animation-name: zoom;
	animation-duration: $duration;
	animation-fill-mode: forwards;
	animation-delay: $delay;

	@keyframes zoom {
		from {
			opacity: 0;
			transform: scale(0.01);
		}
		to {
			opacity: 1;
			transform: scale(1);
		}
	}
}

@mixin left-slide-animation($duration: 1s, $delay: 0s) {
	opacity: 0;
	animation-name: left-slide;
	animation-delay: $delay;
	animation-duration: $duration;
	animation-fill-mode: forwards;
	@keyframes left-slide {
		0%,
		25% {
			transform: translateX(-100%);
			left: 0%;
			opacity: 0;
		}
		50% {
			opacity: 0;
		}
		100% {
			transform: translateX(0%);
			left: 100%;
			opacity: 1;
		}
	}
}

@mixin pulse($duration: 0.7s, $delay: 0s) {
	animation-name: pulse;
	animation-delay: $delay;
	animation-duration: $duration;
	animation-fill-mode: forwards;
	@keyframes pulse {
		33% {
			transform: scale(0.5);
		}
		68% {
			transform: scale(1.3);
		}
		100% {
			transform: scale(1);
		}
	}
}

@mixin live-gradient() {
	animation: gradient 8s ease infinite;
	@keyframes gradient {
		0% {
			background-position: 0% 50%;
		}
		20% {
			background-position: 100% 50%;
		}
		40% {
			background-position: 0% 100%;
		}
	}
}

@mixin sticky($top: 0px) {
	position: -webkit-sticky;
	position: sticky;
	top: $top;
}
